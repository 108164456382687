import React from "react"

export default () => (
<table>
  <thead>
      <tr>
         <th scope="col">Description</th>
         <th scope="col">Audio Language Tag</th>
         <th scope="col">Visual Language Tag</th>
         <th scope="col">Sign Language Tag</th>
      </tr>
   </thead>
<tbody>
<tr>
<td>
<p>Arabic (Tunisian)</p>
</td>
<td>
<p>aeb</p>
</td>
<td>
<p>aeb</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Afrikaans</p>
</td>
<td>
<p>af</p>
</td>
<td>
<p>af</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Albanian (Tosk)</p>
</td>
<td>
<p>als</p>
</td>
<td>
<p>als</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Amharic</p>
</td>
<td>
<p>am</p>
</td>
<td>
<p>am</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Arabic (Standard)</p>
</td>
<td>
<p>arb</p>
</td>
<td>
<p>arb</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Arabic (Najdi)</p>
</td>
<td>
<p>ars</p>
</td>
<td>
<p>ars</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>American Sign Language</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
<td>
<p>ase</p>
</td>
</tr>
<tr>
<td>
<p>Azerbaijani (North)</p>
</td>
<td>
<p>azj</p>
</td>
<td>
<p>azj</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Belarusian</p>
</td>
<td>
<p>be</p>
</td>
<td>
<p>be</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Bulgarian</p>
</td>
<td>
<p>bg</p>
</td>
<td>
<p>bg</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Bislama</p>
</td>
<td>
<p>bi</p>
</td>
<td>
<p>bi</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Bengali (Bangladesh)</p>
</td>
<td>
<p>bn-BD</p>
</td>
<td>
<p>bn-BD</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Bengali (India)</p>
</td>
<td>
<p>bn-IN</p>
</td>
<td>
<p>bn-IN</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Bosnian</p>
</td>
<td>
<p>bs</p>
</td>
<td>
<p>bs</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Brazilian Sign Language</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
<td>
<p>bzs</p>
</td>
</tr>
<tr>
<td>
<p>Catalan (Spain)</p>
</td>
<td>
<p>ca-ES</p>
</td>
<td>
<p>ca-ES</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Valencian</p>
</td>
<td>
<p>ca-valencia</p>
</td>
<td>
<p>ca-valencia</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Kurdish (Central/Sorani)</p>
</td>
<td>
<p>ckb</p>
</td>
<td>
<p>ckb</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Mandarin (Undifferentiated)</p>
</td>
<td>
<p></p>
</td>
<td>
<p>cmn</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Mandarin (Mainland China)</p>
</td>
<td>
<p></p>
</td>
<td>
<p>cmn-CN</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Mandarin (Singapore)</p>
</td>
<td>
<p></p>
</td>
<td>
<p>cmn-SG</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Mandarin (Taiwan)</p>
</td>
<td>
<p></p>
</td>
<td>
<p>cmn-TW</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Montenegrin</p>
</td>
<td>
<p></p>
</td>
<td>
<p>cnr</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Montenegrin (Latin Script)</p>
</td>
<td>
<p>cnr-Latn</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Seselwa Creole French</p>
</td>
<td>
<p>crs</p>
</td>
<td>
<p>crs</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Czech</p>
</td>
<td>
<p>cs</p>
</td>
<td>
<p>cs</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Welsh</p>
</td>
<td>
<p>cy</p>
</td>
<td>
<p>cy</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Danish</p>
</td>
<td>
<p>da</p>
</td>
<td>
<p>da</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>German (Undifferentiated)</p>
</td>
<td>
<p>de</p>
</td>
<td>
<p>de</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>German (Austria)</p>
</td>
<td>
<p>de-AT</p>
</td>
<td>
<p>de-AT</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>German (Switzerland)</p>
</td>
<td>
<p>de-CH</p>
</td>
<td>
<p>de-CH</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>German (Germany)</p>
</td>
<td>
<p>de-DE</p>
</td>
<td>
<p>de-DE</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Divehi</p>
</td>
<td>
<p>dv</p>
</td>
<td>
<p>dv</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Dzongkha</p>
</td>
<td>
<p>dz</p>
</td>
<td>
<p>dz</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Estonian</p>
</td>
<td>
<p>ekk</p>
</td>
<td>
<p>ekk</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Greek</p>
</td>
<td>
<p>el</p>
</td>
<td>
<p>el</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>English (Undifferentiated)</p>
</td>
<td>
<p>en</p>
</td>
<td>
<p>en</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>English (Latin American)</p>
</td>
<td>
<p>en-419</p>
</td>
<td>
<p>en-419</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>English (Australia)</p>
</td>
<td>
<p>en-AU</p>
</td>
<td>
<p>en-AU</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>English (United Kingdom)</p>
</td>
<td>
<p>en-GB</p>
</td>
<td>
<p>en-GB</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>English (New Zealand)</p>
</td>
<td>
<p>en-NZ</p>
</td>
<td>
<p>en-NZ</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>English (United States)</p>
</td>
<td>
<p>en-US</p>
</td>
<td>
<p>en-US</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Spanish (Undifferentiated)</p>
</td>
<td>
<p>es</p>
</td>
<td>
<p>es</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Spanish (Latin American)</p>
</td>
<td>
<p>es-419</p>
</td>
<td>
<p>es-419</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Spanish (Argentinian)</p>
</td>
<td>
<p>es-AR</p>
</td>
<td>
<p>es-AR</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Spanish (Chile)</p>
</td>
<td>
<p>es-CL</p>
</td>
<td>
<p>es-CL</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Spanish (Castilian)</p>
</td>
<td>
<p>es-ES</p>
</td>
<td>
<p>es-ES</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Basque</p>
</td>
<td>
<p>eu</p>
</td>
<td>
<p>eu</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Finnish</p>
</td>
<td>
<p>fi</p>
</td>
<td>
<p>fi</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Filipino</p>
</td>
<td>
<p>fil</p>
</td>
<td>
<p>fil</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Fijian</p>
</td>
<td>
<p>fj</p>
</td>
<td>
<p>fj</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Faroese</p>
</td>
<td>
<p>fo</p>
</td>
<td>
<p>fo</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>French (Undifferentiated)</p>
</td>
<td>
<p>fr</p>
</td>
<td>
<p>fr</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>French (Canadian)</p>
</td>
<td>
<p>fr-CA</p>
</td>
<td>
<p>fr-CA</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>French (France)</p>
</td>
<td>
<p>fr-FR</p>
</td>
<td>
<p>fr-FR</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>French Sign Language</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
<td>
<p>fsl</p>
</td>
</tr>
<tr>
<td>
<p>Irish</p>
</td>
<td>
<p>ga</p>
</td>
<td>
<p>ga</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Galician</p>
</td>
<td>
<p>gl</p>
</td>
<td>
<p>gl</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Gujarati</p>
</td>
<td>
<p>gu</p>
</td>
<td>
<p>gu</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Hebrew</p>
</td>
<td>
<p>he</p>
</td>
<td>
<p>he</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Hindi</p>
</td>
<td>
<p>hi</p>
</td>
<td>
<p>hi</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Hindi (Fiji)</p>
</td>
<td>
<p>hif</p>
</td>
<td>
<p>hif</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Croatian</p>
</td>
<td>
<p>hr</p>
</td>
<td>
<p>hr</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Hungarian</p>
</td>
<td>
<p>hu</p>
</td>
<td>
<p>hu</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Armenian</p>
</td>
<td>
<p>hy</p>
</td>
<td>
<p>hy</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Indonesian</p>
</td>
<td>
<p>id</p>
</td>
<td>
<p>id</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Icelandic</p>
</td>
<td>
<p>is</p>
</td>
<td>
<p>is</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Italian (Undifferentiated)</p>
</td>
<td>
<p>it</p>
</td>
<td>
<p>it</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Italian (Switzerland)</p>
</td>
<td>
<p>it-CH</p>
</td>
<td>
<p>it-CH</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Italian (Italy)</p>
</td>
<td>
<p>it-IT</p>
</td>
<td>
<p>it-IT</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Japanese</p>
</td>
<td>
<p></p>
</td>
<td>
<p>ja</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Japanese (Hiragana)</p>
</td>
<td>
<p>ja-Hira</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Japanese (Han Hiragana and Katakana)</p>
</td>
<td>
<p>ja-Jpan</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Japanese (Katakana)</p>
</td>
<td>
<p>ja-Kana</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Georgian</p>
</td>
<td>
<p>ka</p>
</td>
<td>
<p>ka</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Khalkha Mongolian</p>
</td>
<td>
<p></p>
</td>
<td>
<p>khk</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Khalkha Mongolian (Cyrillic Script)</p>
</td>
<td>
<p>khk-Cyrl</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Khallka Mongolian (Mongolian Script)</p>
</td>
<td>
<p>khk-Mong</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kazakh</p>
</td>
<td>
<p>kk</p>
</td>
<td>
<p>kk</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kalaallisut</p>
</td>
<td>
<p>kl</p>
</td>
<td>
<p>kl</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Central Khmer</p>
</td>
<td>
<p>km</p>
</td>
<td>
<p>km</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kurdish (Northern/Kurmanji)</p>
</td>
<td>
<p>kmr</p>
</td>
<td>
<p>kmr</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kannada</p>
</td>
<td>
<p>kn</p>
</td>
<td>
<p>kn</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Korean</p>
</td>
<td>
<p>ko</p>
</td>
<td>
<p>ko</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kirghiz</p>
</td>
<td>
<p></p>
</td>
<td>
<p>ky</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kirghiz (Latin Script)</p>
</td>
<td>
<p>ky-Latn</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Latin</p>
</td>
<td>
<p>la</p>
</td>
<td>
<p>la</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Luxembourgish</p>
</td>
<td>
<p>lb</p>
</td>
<td>
<p>lb</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Ganda</p>
</td>
<td>
<p>lg</p>
</td>
<td>
<p>lg</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Lao</p>
</td>
<td>
<p>lo</p>
</td>
<td>
<p>lo</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Lithuanian</p>
</td>
<td>
<p>lt</p>
</td>
<td>
<p>lt</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Latvian</p>
</td>
<td>
<p>lvs</p>
</td>
<td>
<p>lvs</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Motu</p>
</td>
<td>
<p>meu</p>
</td>
<td>
<p>meu</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Malagasy</p>
</td>
<td>
<p>mg-MG</p>
</td>
<td>
<p>mg-MG</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Macedonian</p>
</td>
<td>
<p>mk</p>
</td>
<td>
<p>mk</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Malayalam</p>
</td>
<td>
<p>ml</p>
</td>
<td>
<p>ml</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Marathi</p>
</td>
<td>
<p>mr</p>
</td>
<td>
<p>mr</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Maltese</p>
</td>
<td>
<p>mt</p>
</td>
<td>
<p>mt</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Burmese</p>
</td>
<td>
<p>my</p>
</td>
<td>
<p>my</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Nauru</p>
</td>
<td>
<p>na</p>
</td>
<td>
<p>na</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Min Nan Chinese</p>
</td>
<td>
<p></p>
</td>
<td>
<p>nan</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Neapolitan</p>
</td>
<td>
<p>nap</p>
</td>
<td>
<p>nap</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Norwegian Bokmål</p>
</td>
<td>
<p>nb</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Dutch</p>
</td>
<td>
<p>nl</p>
</td>
<td>
<p>nl</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Norwegian Nyorsk</p>
</td>
<td>
<p>nn</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Norwegian</p>
</td>
<td>
<p></p>
</td>
<td>
<p>no</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Nepali</p>
</td>
<td>
<p>npi</p>
</td>
<td>
<p>npi</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Nyanja Chewa</p>
</td>
<td>
<p>ny</p>
</td>
<td>
<p>ny</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Aranes</p>
</td>
<td>
<p>oc-aranes</p>
</td>
<td>
<p>oc-aranes</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Punjabi</p>
</td>
<td>
<p>pa</p>
</td>
<td>
<p>pa</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Palauan</p>
</td>
<td>
<p>pau</p>
</td>
<td>
<p>pau</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Farsi (Iranian Persian)</p>
</td>
<td>
<p>pes</p>
</td>
<td>
<p>pes</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Pitcairn-Norfolk</p>
</td>
<td>
<p>pih</p>
</td>
<td>
<p>pih</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Polish</p>
</td>
<td>
<p>pl</p>
</td>
<td>
<p>pl</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Western Panjabi</p>
</td>
<td>
<p>pnb</p>
</td>
<td>
<p>pnb</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Dari (Afghanistan)</p>
</td>
<td>
<p>prs</p>
</td>
<td>
<p>prs</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Pashto</p>
</td>
<td>
<p>ps-AF</p>
</td>
<td>
<p>ps-AF</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Portuguese (Brazilian)</p>
</td>
<td>
<p>pt-BR</p>
</td>
<td>
<p>pt-BR</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Portuguese (European)</p>
</td>
<td>
<p>pt-PT</p>
</td>
<td>
<p>pt-PT</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Rundi</p>
</td>
<td>
<p>rn</p>
</td>
<td>
<p>rn</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Romanian</p>
</td>
<td>
<p>ro</p>
</td>
<td>
<p>ro</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Russian</p>
</td>
<td>
<p>ru</p>
</td>
<td>
<p>ru</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kinyarwanda</p>
</td>
<td>
<p>rw</p>
</td>
<td>
<p>rw</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Kurdish (Southern/Pehlewani)</p>
</td>
<td>
<p>sdh</p>
</td>
<td>
<p>sdh</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Sinhalese</p>
</td>
<td>
<p>si</p>
</td>
<td>
<p>si</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Slovak</p>
</td>
<td>
<p>sk</p>
</td>
<td>
<p>sk</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Slovenian</p>
</td>
<td>
<p>sl</p>
</td>
<td>
<p>sl</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Samoan</p>
</td>
<td>
<p>sm</p>
</td>
<td>
<p>sm</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Somali</p>
</td>
<td>
<p>so</p>
</td>
<td>
<p>so</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Serbian</p>
</td>
<td>
<p></p>
</td>
<td>
<p>sr</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Serbian (Latin Script)</p>
</td>
<td>
<p>sr-Latn</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Sranan Tongo</p>
</td>
<td>
<p>srn</p>
</td>
<td>
<p>srn</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Swati</p>
</td>
<td>
<p>ss</p>
</td>
<td>
<p>ss</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Southern Sotho</p>
</td>
<td>
<p>st</p>
</td>
<td>
<p>st</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Swedish</p>
</td>
<td>
<p>sv</p>
</td>
<td>
<p>sv</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Swahili</p>
</td>
<td>
<p>swh</p>
</td>
<td>
<p>swh</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Swedish Sign Language</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
<td>
<p>swl</p>
</td>
</tr>
<tr>
<td>
<p>Tamil</p>
</td>
<td>
<p>ta</p>
</td>
<td>
<p>ta</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Telugu</p>
</td>
<td>
<p>te</p>
</td>
<td>
<p>te</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tajik</p>
</td>
<td>
<p></p>
</td>
<td>
<p>tg</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tajik (Cyrillic Script)</p>
</td>
<td>
<p>tg-Cyrl</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Thai</p>
</td>
<td>
<p>th</p>
</td>
<td>
<p>th</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tigrinya</p>
</td>
<td>
<p>ti</p>
</td>
<td>
<p>ti</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Turkmen</p>
</td>
<td>
<p>tk</p>
</td>
<td>
<p>tk</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tagalog</p>
</td>
<td>
<p>tl</p>
</td>
<td>
<p>tl</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tswana</p>
</td>
<td>
<p>tn</p>
</td>
<td>
<p>tn</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tongan</p>
</td>
<td>
<p>to</p>
</td>
<td>
<p>to</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tok Pisin</p>
</td>
<td>
<p>tpi</p>
</td>
<td>
<p>tpi</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Turkish</p>
</td>
<td>
<p>tr</p>
</td>
<td>
<p>tr</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Tsonga</p>
</td>
<td>
<p>ts</p>
</td>
<td>
<p>ts</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Thai Sign Language</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
<td>
<p>tsq</p>
</td>
</tr>

<tr>
<td>
<p>Tuvalu</p>
</td>
<td>
<p>tvl</p>
</td>
<td>
<p>tvl</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Tahitian</p>
</td>
<td>
<p>ty</p>
</td>
<td>
<p>ty</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Ukrainian</p>
</td>
<td>
<p>uk</p>
</td>
<td>
<p>uk</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Undetermined</p>
</td>
<td>
<p>und</p>
</td>
<td>
<p>und</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Urdu</p>
</td>
<td>
<p>ur</p>
</td>
<td>
<p>ur</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Uzbek</p>
</td>
<td>
<p>uz</p>
</td>
<td>
<p>uz</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Vietnamese</p>
</td>
<td>
<p>vi</p>
</td>
<td>
<p>vi</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Vlaams</p>
</td>
<td>
<p>vls</p>
</td>
<td>
<p>vls</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Wolof</p>
</td>
<td>
<p>wo</p>
</td>
<td>
<p>wo</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Yiddish</p>
</td>
<td>
<p>yi</p>
</td>
<td>
<p>yi</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Cantonese</p>
</td>
<td>
<p></p>
</td>
<td>
<p>yue</p>
</td>
<td>
<p></p>
</td>
</tr>

<tr>
<td>
<p>Chinese (Simplified) (Mainland China)</p>
</td>
<td>
<p>zh-Hans-CN</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Chinese (Simplified) (Taiwan)</p>
</td>
<td>
<p>zh-Hans-TW</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Chinese (Traditional) (Hong Kong)</p>
</td>
<td>
<p>zh-Hant-HK</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Chinese (Traditional) (Taiwan)</p>
</td>
<td>
<p>zh-Hant-TW</p>
</td>
<td>
<p></p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Malay (Standard)</p>
</td>
<td>
<p>zsm</p>
</td>
<td>
<p>zsm</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>Zulu</p>
</td>
<td>
<p>zu</p>
</td>
<td>
<p>zu</p>
</td>
<td>
<p></p>
</td>
</tr>
<tr>
<td>
<p>No Linguistic Content</p>
</td>
<td>
<p>zxx</p>
</td>
<td>
<p>zxx</p>
</td>
<td>
<p></p>
</td>
</tr>
</tbody>
</table>)
